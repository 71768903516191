.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
    padding: 10px;
}

.header h1 {
    margin: 0;
}

.icon-link {
    text-decoration: none;
    color: #333;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    border: 0;
    font-size: 20px;
}

.icon-link:hover {
    opacity: 0.7;
}

.icon-link:focus {
    outline: 0;
}

.app-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-title,
.app-subtitle,
.admin-app-title {
    margin: 10px 0 0;
}

.admin-app-title > * {
    margin: 0;
}

.admin-app-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 25em;
}

.admin-title-actions {
    display: flex;
    align-items: center;
}

.admin-title-actions > * {
    margin-left: .5em;
}

.admin-edit-access-links,
.access-links {
    display: flex;
    margin-top: 10px;
}

.access-links a {
    margin: 0 10px;
}

.admin-edit-access-links {
    width: 100%;
    max-width: 25em;
    justify-content: space-between;
    align-items: center;
}

.admin-edit-access-links .url-key-input {
    margin-right: 1em;
}

.admin-edit-access-links select.url-key-input {
    width: 7em;
}

.admin-edit-access-links input.url-key-input {
    width: 6em;
}

.admin-edit-access-links .url-input {
    flex: auto;
}

.admin-edit-access-links button {
    margin-left: 1em;
}

.page-link {
    margin-top: 10px;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-group {
    align-self: stretch;
    padding: 0 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.input-group input,
.input-group textarea {
    font-size: 20px;
    padding: 5px;
}

.input-group textarea {
    resize: none;
    height: 5em;
    font-family: inherit;
}

.item-content {
    align-self: stretch;
    padding: 0 20px;
}

.item-content h3 {
    margin-top: 0;
}

.item-content pre {
    font-family: inherit;
}

.admin-new-app-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.admin-item {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    width: 100%;
    max-width: 20em;
    margin-bottom: .5em;
}
